import { BaseModel } from "./base.model";

export class StaticPage extends BaseModel {
  constructor(
    public active: boolean,
    public title: {[lang:string]: string},
    public link: string,
    public html: {[lang:string]: string}
  ) {
    super();
  }

}
